import { defineNuxtRouteMiddleware, useNuxtApp } from '#app';
import useGeneralStore from '@/stores/useGeneralStore';
import { isDashboardOrRealm } from '~/utils/config';

export default defineNuxtRouteMiddleware((_to) => {
  // will implement middleware rules if any
  const genObj = useGeneralStore();
  if (isDashboardOrRealm(_to)) {
    return isDashboardOrRealm(_to);
  }
  if (genObj.realmId === 0 && process.client) {
    useNuxtApp().$event('openRealmModel');
  }
});
